// import React, { useState } from 'react';
// import axios from 'axios';
// import logo from './logo.png';
// import './EmailComposer.css';

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [body, setBody] = useState('');

//   const handleSend = async () => {
//     try {
//       const response = await axios.post('http://localhost:3002/send-email', { subject, body });
//       alert(response.data);
//     } catch (error) {
//       console.error('There was an error!', error);
//     }
//   };

//   return (
//     <div className="email-composer-container">
//       <img src={logo} alt="Logo" className="logo" />
//       <h1>Compose Email</h1>
//       <div className="form-group">
//         <label>Subject:</label>
//         <input
//           type="text"
//           value={subject}
//           onChange={(e) => setSubject(e.target.value)}
//           className="form-control"
//           placeholder="Email Subject"
//         />
//       </div>
//       <div className="form-group">
//         <label>Body:</label>
//         <textarea
//           value={body}
//           onChange={(e) => setBody(e.target.value)}
//           className="form-control"
//           placeholder="Compose your email content here..."
//           rows="10"
//         />
//       </div>
//       <button onClick={handleSend} className="btn btn-primary">Send</button>
//     </div>
//   );
// }

// export default EmailComposer;

// import React, { useState } from 'react';
// import axios from 'axios';
// import logo from './logo.png';
// import './EmailComposer.css';

// function Composer() {
//   const [subject, setSubject] = useState('');
//   const [body, setBody] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:3002/send-email', { subject, body });
//       alert(response.data);
//     } catch (error) {
//       console.error('There was an error!', error);
//     }
//   };

//   return (
//     <div className="container">
//       <img src={logo} alt="Logo" className="logo" />
//       <h1>Email Composer</h1>
//       <form onSubmit={handleSubmit} className="form">
//         <div className="form-group">
//           <label>Subject: </label>
//           <input 
//             type="text" 
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             required 
//           />
//         </div>
//         <div className="form-group">
//           <label>Body: </label>
//           <textarea 
//             value={body}
//             onChange={(e) => setBody(e.target.value)}
//             required
//             rows="10"
//           />
//         </div>
//         <button type="submit" className="btn">Send Email</button>
//       </form>
//     </div>
//   );
// }

// export default Composer;

// EmailComposer.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import './EmailComposer.css';
// import logo from './logo.png';

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [body, setBody] = useState('');

//   const handleGenerateBody = async () => {
//     try {
//       const response = await axios.post('http://localhost:3002/generate-email-body', { subject });
//       setBody(response.data.emailBody);
//     } catch (error) {
//       console.error('Error generating email body:', error);
//     }
//   };

//   const handleSendEmail = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:3002/send-email', { subject, body });
//       alert(response.data);
//     } catch (error) {
//       console.error('Error sending emails:', error);
//     }
//   };

//   return (
//     <div className="email-composer-container">
//       <img src={logo} alt="Logo" className="logo" />
//       <h1>Compose Email</h1>
//       <form onSubmit={handleSendEmail}>
//         <div className="form-group">
//           <label>Subject: </label>
//           <input
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             required
//           />
//           {subject && (
//             <button type="button" onClick={handleGenerateBody}>
//               Let me write for you!
//             </button>
//           )}
//         </div>
//         <div className="form-group">
//           <label>Body: </label>
//           <textarea
//             value={body}
//             onChange={(e) => setBody(e.target.value)}
//             rows="10"
//             required
//           />
//         </div>
//         <button type="submit">Send</button>
//       </form>
//     </div>
//   );
// }

// export default EmailComposer;

// import React, { useState } from 'react';
// import axios from 'axios';
// import './EmailComposer.css';
// import logo from './logo.png';
// import { Editor } from '@tinymce/tinymce-react';

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [editorContent, setEditorContent] = useState('');
//   const [aiText, setAiText] = useState('');
//   const [isWriting, setIsWriting] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [message, setMessage] = useState('');

//   const handleGenerateBody = async () => {
//     try {
//       const response = await axios.post('http://localhost:3002/generate-email-body', { subject });
//       setEditorContent(response.data.emailBody);
//     } catch (error) {
//       console.error('Error generating email body:', error);
//     }
//   };

//   const handleSendEmail = async (e) => {
//     e.preventDefault();
//     if (!subject || !editorContent) {
//       alert('Subject and email body cannot be empty');
//       return;
//     }

//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://localhost:3002/send-email', { subject, body: editorContent });
//       setMessage(`Emails sent successfully to ${response.data.count} recipients.`);
//     } catch (error) {
//       console.error('Error sending emails:', error);
//       setMessage('Failed to send emails');
//     }
//     setIsLoading(false);
//   };

//   const handleStartWriting = async () => {
//     if (!aiText) {
//       alert('Please enter text to expand');
//       return;
//     }

//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://localhost:3002/generate-email-body', { subject: aiText });
//       setEditorContent(`Hi,\n\n${response.data.emailBody}\n\nBest,\nTeam`);
//     } catch (error) {
//       console.error('Error generating email body:', error);
//     }
//     setIsLoading(false);
//   };

//   return (
//     <div className="email-composer-container">
//       <img src={logo} alt="Logo" className="logo" />
//       <h1>Compose Email</h1>
//       <button type="button" onClick={() => setIsWriting(!isWriting)}>Use AI to Write Email</button>
//       {isWriting && (
//         <div className="ai-writing-container">
//           <input
//             type="text"
//             placeholder="What you want to write"
//             value={aiText}
//             onChange={(e) => setAiText(e.target.value)}
//           />
//           <button type="button" onClick={handleStartWriting}>Start</button>
//         </div>
//       )}
//       <form onSubmit={handleSendEmail}>
//         <div className="form-group">
//           <label>Subject: </label>
//           <input
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Body: </label>
//           <Editor
//             apiKey="cip496nev8yn4ctfi6baks8hfye8mnc8ockgswwnhjylp6qy" // Replace with your TinyMCE API key
//             value={editorContent}
//             onEditorChange={(content) => setEditorContent(content)}
//             init={{
//               height: 300,
//               menubar: false,
//               plugins: [
//                 'advlist autolink lists link image charmap print preview anchor',
//                 'searchreplace visualblocks code fullscreen',
//                 'insertdatetime media table paste code help wordcount'
//               ],
//               toolbar:
//                 'undo redo | formatselect | bold italic backcolor | \
//                 alignleft aligncenter alignright alignjustify | \
//                 bullist numlist outdent indent | removeformat | help'
//             }}
//           />
//         </div>
//         <button type="submit" disabled={isLoading}>Send</button>
//         {isLoading && <div className="loader"></div>}
//         {message && <div className="message">{message}</div>}
//       </form>
//     </div>
//   );
// }

// export default EmailComposer;

//2-8-24 12:10am

// import React, { useState } from 'react';
// import axios from 'axios';
// import './EmailComposer.css';
// import logo from './logo.png';
// import { Editor } from '@tinymce/tinymce-react';

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [editorContent, setEditorContent] = useState('');
//   const [aiText, setAiText] = useState('');
//   const [aiResponse, setAiResponse] = useState('');
//   const [isWriting, setIsWriting] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [message, setMessage] = useState('');

//   const handleGenerateBody = async () => {
//     try {
//       const response = await axios.post('http://localhost:3002/generate-email-body', { subject });
//       setEditorContent(response.data.emailBody);
//     } catch (error) {
//       console.error('Error generating email body:', error);
//     }
//   };

//   const handleSendEmail = async (e) => {
//     e.preventDefault();
//     if (!subject || !editorContent) {
//       alert('Subject and email body cannot be empty');
//       return;
//     }

//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://localhost:3002/send-email', { subject, body: editorContent });
//       setMessage(`Emails sent successfully to ${response.data.count} recipients.`);
//     } catch (error) {
//       console.error('Error sending emails:', error);
//       setMessage('Failed to send emails');
//     }
//     setIsLoading(false);
//   };

//   const handleStartWriting = async () => {
//     if (!aiText) {
//       alert('Please enter text to expand');
//       return;
//     }

//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://localhost:3002/generate-email-body', { subject: aiText });
//       setAiResponse(response.data.emailBody);
//     } catch (error) {
//       console.error('Error generating email body:', error);
//       alert('Failed to generate email body. Please try again later.');
//     }
//     setIsLoading(false);
//   };

//   return (
//     <div className="email-composer-container">
//       <img src={logo} alt="Logo" className="logo" />
//       <h1 className="title">Compose Email</h1>
//       <button 
//         className="toggle-ai-button" 
//         type="button" 
//         onClick={() => setIsWriting(!isWriting)}
//       >
//         {isWriting ? 'Cancel AI Writing' : 'Use AI to Write Email'}
//       </button>
//       {isWriting && (
//         <div className="ai-writing-container">
//           <input
//             className="ai-input"
//             type="text"
//             placeholder="Enter text to expand"
//             value={aiText}
//             onChange={(e) => setAiText(e.target.value)}
//           />
//           <button className="ai-generate-button" type="button" onClick={handleStartWriting}>Generate</button>
//           {aiResponse && (
//             <textarea
//               className="ai-response-textbox"
//               value={aiResponse}
//               readOnly
//             />
//           )}
//         </div>
//       )}
//       <form className="email-form" onSubmit={handleSendEmail}>
//         <div className="form-group">
//           <label>Subject:</label>
//           <input
//             className="form-control"
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Body:</label>
//           <Editor
//             apiKey="cip496nev8yn4ctfi6baks8hfye8mnc8ockgswwnhjylp6qy" // Replace with your TinyMCE API key
//             value={editorContent}
//             onEditorChange={(content) => setEditorContent(content)}
//             init={{
//               height: 300,
//               menubar: false,
//               plugins: [
//                 'advlist autolink lists link image charmap print preview anchor',
//                 'searchreplace visualblocks code fullscreen',
//                 'insertdatetime media table paste code help wordcount'
//               ],
//               toolbar:
//                 'undo redo | formatselect | bold italic backcolor | \
//                 alignleft aligncenter alignright alignjustify | \
//                 bullist numlist outdent indent | removeformat | help | \
//                 image'
//             }}
//           />
//         </div>
//         <button className="send-button" type="submit" disabled={isLoading}>Send</button>
//         {isLoading && <div className="loader"></div>}
//         {message && <div className="message">{message}</div>}
//       </form>
//     </div>
//   );
// }

// export default EmailComposer;

//2-aug-24  11:24pm


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Editor } from '@tinymce/tinymce-react';
// import './EmailComposer.css';
// import logo from './logo.png';

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [editorContent, setEditorContent] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [message, setMessage] = useState('');
//   const [groups, setGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState('');

//   useEffect(() => {
//     const fetchGroups = async () => {
//       try {
//         const response = await axios.get('http://localhost:3002/groups');
//         setGroups(response.data.groups);
//         if (response.data.groups.length > 0) {
//           setSelectedGroup(response.data.groups[0]);
//         }
//       } catch (error) {
//         console.error('Error fetching groups:', error);
//       }
//     };
//     fetchGroups();
//   }, []);

//   const handleSendEmail = async (e) => {
//     e.preventDefault();
//     if (!subject || !editorContent || !selectedGroup) {
//       alert('Subject, email body, and group cannot be empty');
//       return;
//     }

//     setIsLoading(true);
//     try {
//       const response = await axios.post('http://localhost:3002/send-email', { subject, body: editorContent, group: selectedGroup });
//       setMessage(`Emails sent successfully to ${response.data.count} recipients.`);
//     } catch (error) {
//       console.error('Error sending emails:', error);
//       setMessage('Failed to send emails');
//     }
//     setIsLoading(false);
//   };

//   const handleImageUpload = async (blobInfo, success, failure) => {
//     const formData = new FormData();
//     formData.append('file', blobInfo.blob(), blobInfo.filename());

//     try {
//       const response = await axios.post('http://localhost:3002/upload-image', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       success(response.data.location);
//     } catch (error) {
//       console.error('Error uploading image:', error);
//       failure('Image upload failed');
//     }
//   };

//   return (
//     <div className="email-composer-container">
//       <img src={logo} alt="Logo" className="logo" />
//       <h1 className="title">Compose Email</h1>
//       <form className="email-form" onSubmit={handleSendEmail}>
//         <div className="form-group">
//           <label>Subject:</label>
//           <input
//             className="form-control"
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Group:</label>
//           <select
//             className="form-control"
//             value={selectedGroup}
//             onChange={(e) => setSelectedGroup(e.target.value)}
//             required
//           >
//             {groups.map((group, index) => (
//               <option key={index} value={group}>
//                 {group}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="form-group">
//           <label>Body:</label>
//           <Editor
//             apiKey="cip496nev8yn4ctfi6baks8hfye8mnc8ockgswwnhjylp6qy" // Replace with your TinyMCE API key
//             value={editorContent}
//             onEditorChange={(content) => setEditorContent(content)}
//             init={{
//               height: 300,
//               menubar: false,
//               plugins: [
//                 'advlist autolink lists link image charmap print preview anchor',
//                 'searchreplace visualblocks code fullscreen',
//                 'insertdatetime media table paste code help wordcount'
//               ],
//               toolbar:
//                 'undo redo | formatselect | bold italic backcolor | \
//                 alignleft aligncenter alignright alignjustify | \
//                 bullist numlist outdent indent | removeformat | help | \
//                 image',
//               images_upload_handler: handleImageUpload
//             }}
//           />
//         </div>
//         <button className="send-button" type="submit" disabled={isLoading}>
//           Send
//         </button>
//         {isLoading && <div className="loader"></div>}
//         {message && <div className="message">{message}</div>}
//       </form>
//     </div>
//   );
// }

// export default EmailComposer;


// 3-aug-24 12:06am

// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import Quill from 'quill';
// import 'quill/dist/quill.snow.css';
// import './EmailComposer.css';
// import logo from './logo.png'; // Ensure you have a logo.png file in your project

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [groups, setGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState('');
//   const [files, setFiles] = useState([]);
//   const [uploadMessage, setUploadMessage] = useState('');
//   const quillRef = useRef(null);

//   useEffect(() => {
//     // Initialize Quill editor
//     if (!quillRef.current) {
//       quillRef.current = new Quill('#editor-container', {
//         theme: 'snow',
//         modules: {
//           toolbar: [
//             [{ header: '1' }, { header: '2' }],
//             ['bold', 'italic', 'underline'],
//             [{ list: 'ordered' }, { list: 'bullet' }],
//             ['link', 'image']
//           ]
//         }
//       });

//       // Handle image pasting
//       quillRef.current.getModule('toolbar').addHandler('image', () => {
//         document.querySelector('#file-input').click();
//       });
//     }

//     // Fetch groups from the server
//     axios.get('http://localhost:3002/groups')
//       .then(response => {
//         setGroups(response.data.groups);
//       })
//       .catch(error => {
//         console.error('Error fetching groups:', error);
//       });
//   }, []);

//   const handleFileUpload = (e) => {
//     const filesArray = Array.from(e.target.files);
//     setFiles(prevFiles => [...prevFiles, ...filesArray]);
//     setUploadMessage('File(s) uploaded successfully');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const editorHtml = quillRef.current.root.innerHTML;

//     const formData = new FormData();
//     formData.append('subject', subject);
//     formData.append('body', editorHtml);
//     formData.append('group', selectedGroup);

//     // Append files to FormData
//     files.forEach(file => {
//       formData.append('attachments', file);
//     });

//     try {
//       const response = await axios.post('http://localhost:3002/send-email', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       alert(response.data);
//     } catch (error) {
//       console.error('There was an error!', error);
//     }
//   };

//   return (
//     <div className="email-composer">
//       <div className="header">
//         <img src={logo} alt="Logo" className="logo" />
//         <h1>Email Composer</h1>
//       </div>
//       <form onSubmit={handleSubmit} className="form-container">
//         <div className="form-group">
//           <label>Subject:</label>
//           <input
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             className="form-control"
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Group:</label>
//           <select
//             value={selectedGroup}
//             onChange={(e) => setSelectedGroup(e.target.value)}
//             className="form-control"
//             required
//           >
//             <option value="">Select a group</option>
//             {groups.map((group, index) => (
//               <option key={index} value={group}>{group}</option>
//             ))}
//           </select>
//         </div>
//         <div id="editor-container" className="editor-container"></div>
//         <input
//           id="file-input"
//           type="file"
//           accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//           multiple
//           onChange={handleFileUpload}
//           style={{ display: 'none' }}
//         />
//         <button
//           type="button"
//           className="btn btn-secondary attach-button"
//           onClick={() => document.querySelector('#file-input').click()}
//         >
//           Attach File
//         </button>
//         {files.length > 0 && (
//           <div className="uploaded-files">
//             <h4>Uploaded Files:</h4>
//             <ul>
//               {files.map((file, index) => (
//                 <li key={index}>{file.name}</li>
//               ))}
//             </ul>
//           </div>
//         )}
//         {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
//         <button type="submit" className="btn btn-primary send-button">Send Email</button>
//       </form>
//     </div>
//   );
// }

// export default EmailComposer;

//5-aug-24 12:59am

// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import Quill from 'quill';
// import 'quill/dist/quill.snow.css';
// import './EmailComposer.css';
// import logo from './logo.png'; // Ensure you have a logo.png file in your project

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [groups, setGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState('');
//   const [files, setFiles] = useState([]);
//   const [uploadMessage, setUploadMessage] = useState('');
//   const quillRef = useRef(null);

//   useEffect(() => {
//     // Initialize Quill editor
//     if (!quillRef.current) {
//       quillRef.current = new Quill('#editor-container', {
//         theme: 'snow',
//         modules: {
//           toolbar: [
//             [{ header: '1' }, { header: '2' }],
//             ['bold', 'italic', 'underline'],
//             [{ list: 'ordered' }, { list: 'bullet' }],
//             ['link', 'image']
//           ]
//         }
//       });

//       // Handle image pasting
//       quillRef.current.getModule('toolbar').addHandler('image', () => {
//         document.querySelector('#file-input').click();
//       });
//     }

//     // Fetch groups from the server
//     axios.get('http://localhost:3002/groups')
//       .then(response => {
//         setGroups(response.data.groups);
//       })
//       .catch(error => {
//         console.error('Error fetching groups:', error);
//       });
//   }, []);

//   const handleFileUpload = (e) => {
//     const filesArray = Array.from(e.target.files);
//     setFiles(prevFiles => [...prevFiles, ...filesArray]);
//     setUploadMessage('File(s) uploaded successfully');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const editorHtml = quillRef.current.root.innerHTML;

//     const formData = new FormData();
//     formData.append('subject', subject);
//     formData.append('body', editorHtml);
//     formData.append('group', selectedGroup);

//     // Append files to FormData
//     files.forEach(file => {
//       formData.append('attachments', file);
//     });

//     try {
//       const response = await axios.post('http://localhost:3002/send-email', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       alert('Email sent successfully');
//       setFiles([]); // Clear files after sending
//       setSubject('');
//       setSelectedGroup('');
//       quillRef.current.root.innerHTML = ''; // Clear the editor
//     } catch (error) {
//       console.error('There was an error!', error);
//       alert('Failed to send email');
//     }
//   };

//   return (
//     <div className="email-composer">
//       <div className="header">
//         <img src={logo} alt="Logo" className="logo" />
//         <h1>Email Composer</h1>
//       </div>
//       <form onSubmit={handleSubmit} className="form-container">
//         <div className="form-group">
//           <label>Subject:</label>
//           <input
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             className="form-control"
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Group:</label>
//           <select
//             value={selectedGroup}
//             onChange={(e) => setSelectedGroup(e.target.value)}
//             className="form-control"
//             required
//           >
//             <option value="">Select a group</option>
//             {groups.map((group, index) => (
//               <option key={index} value={group}>{group}</option>
//             ))}
//           </select>
//         </div>
//         <div id="editor-container" className="editor-container"></div>
//         <input
//           id="file-input"
//           type="file"
//           accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//           multiple
//           onChange={handleFileUpload}
//           style={{ display: 'none' }}
//         />
//         <button
//           type="button"
//           className="btn btn-secondary attach-button"
//           onClick={() => document.querySelector('#file-input').click()}
//         >
//           Attach File
//         </button>
//         {files.length > 0 && (
//           <div className="uploaded-files">
//             <h4>Uploaded Files:</h4>
//             <ul>
//               {files.map((file, index) => (
//                 <li key={index}>{file.name}</li>
//               ))}
//             </ul>
//           </div>
//         )}
//         {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
//         <button type="submit" className="btn btn-primary send-button">Send Email</button>
//       </form>
//     </div>
//   );
// }

// export default EmailComposer;

//5-aug-24 8:35pm

// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import Quill from 'quill';
// import 'quill/dist/quill.snow.css';
// import './EmailComposer.css';
// import logo from './logo.png'; // Ensure you have a logo.png file in your project

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [groups, setGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState('');
//   const [files, setFiles] = useState([]);
//   const [uploadMessage, setUploadMessage] = useState('');
//   const quillRef = useRef(null);

//   useEffect(() => {
//     // Initialize Quill editor
//     if (!quillRef.current) {
//       quillRef.current = new Quill('#editor-container', {
//         theme: 'snow',
//         modules: {
//           toolbar: [
//             [{ header: '1' }, { header: '2' }],
//             ['bold', 'italic', 'underline'],
//             [{ list: 'ordered' }, { list: 'bullet' }],
//             ['link', 'image']
//           ]
//         }
//       });

//       // Handle image pasting
//       quillRef.current.getModule('toolbar').addHandler('image', () => {
//         document.querySelector('#file-input').click();
//       });
//     }

//     // Fetch groups from the server
//     axios.get('http://localhost:3002/groups')
//       .then(response => {
//         setGroups(response.data.groups);
//       })
//       .catch(error => {
//         console.error('Error fetching groups:', error);
//       });
//   }, []);

//   const handleFileUpload = (e) => {
//     const filesArray = Array.from(e.target.files);
//     setFiles(prevFiles => [...prevFiles, ...filesArray]);
//     setUploadMessage('File(s) uploaded successfully');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const editorHtml = quillRef.current.root.innerHTML;

//     const formData = new FormData();
//     formData.append('subject', subject);
//     formData.append('body', editorHtml);
//     formData.append('group', selectedGroup);

//     // Append files to FormData
//     files.forEach(file => {
//       formData.append('attachments', file);
//     });

//     try {
//       const response = await axios.post('http://localhost:3002/send-email', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       alert('Email sent successfully');
//       setFiles([]); // Clear files after sending
//       setSubject('');
//       setSelectedGroup('');
//       quillRef.current.root.innerHTML = ''; // Clear the editor
//     } catch (error) {
//       console.error('There was an error!', error);
//       alert('Failed to send email');
//     }
//   };

//   return (
//     <div className="email-composer">
//       <div className="header">
//         <img src={logo} alt="Logo" className="logo" />
//         <h1>Email Composer</h1>
//       </div>
//       <form onSubmit={handleSubmit} className="form-container">
//         <div className="form-group">
//           <label>Subject:</label>
//           <input
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             className="form-control"
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Group:</label>
//           <select
//             value={selectedGroup}
//             onChange={(e) => setSelectedGroup(e.target.value)}
//             className="form-control"
//             required
//           >
//             <option value="">Select a group</option>
//             {groups.map((group, index) => (
//               <option key={index} value={group}>{group}</option>
//             ))}
//           </select>
//         </div>
//         <div id="editor-container" className="editor-container"></div>
//         <input
//           id="file-input"
//           type="file"
//           accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//           multiple
//           onChange={handleFileUpload}
//           style={{ display: 'none' }}
//         />
//         <button
//           type="button"
//           className="btn btn-secondary attach-button"
//           onClick={() => document.querySelector('#file-input').click()}
//         >
//           Attach File
//         </button>
//         {files.length > 0 && (
//           <div className="uploaded-files">
//             <h4>Uploaded Files:</h4>
//             <ul>
//               {files.map((file, index) => (
//                 <li key={index}>{file.name}</li>
//               ))}
//             </ul>
//           </div>
//         )}
//         {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
//         <button type="submit" className="btn btn-primary send-button">Send Email</button>
//       </form>
//     </div>
//   );
// }

// export default EmailComposer;

//5-aug-24 11:37pm

// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import Quill from 'quill';
// import 'quill/dist/quill.snow.css';
// import './EmailComposer.css';
// import logo from './logo.png'; // Ensure you have a logo.png file in your project

// function EmailComposer() {
//   const [subject, setSubject] = useState('');
//   const [groups, setGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState('');
//   const [files, setFiles] = useState([]);
//   const [uploadMessage, setUploadMessage] = useState('');
//   const quillRef = useRef(null);

//   useEffect(() => {
//     // Initialize Quill editor
//     if (!quillRef.current) {
//       quillRef.current = new Quill('#editor-container', {
//         theme: 'snow',
//         modules: {
//           toolbar: [
//             [{ header: '1' }, { header: '2' }],
//             ['bold', 'italic', 'underline'],
//             [{ list: 'ordered' }, { list: 'bullet' }],
//             ['link', 'image']
//           ]
//         }
//       });

//       // Handle image pasting
//       quillRef.current.getModule('toolbar').addHandler('image', () => {
//         document.querySelector('#file-input').click();
//       });
//     }

//     // Fetch groups from the server
//     axios.get('http://localhost:3002/groups')
//       .then(response => {
//         setGroups(response.data.groups);
//       })
//       .catch(error => {
//         console.error('Error fetching groups:', error);
//       });
//   }, []);

//   const handleFileUpload = (e) => {
//     const filesArray = Array.from(e.target.files);
//     setFiles(prevFiles => [...prevFiles, ...filesArray]);
//     setUploadMessage('File(s) uploaded successfully');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let editorHtml = quillRef.current.root.innerHTML;
//     // Append unsubscribe link
//     editorHtml += `<p><a href="http://localhost:3002/unsubscribe">Unsubscribe</a></p>`;

//     const formData = new FormData();
//     formData.append('subject', subject);
//     formData.append('body', editorHtml);
//     formData.append('group', selectedGroup);

//     // Append files to FormData
//     files.forEach(file => {
//       formData.append('attachments', file);
//     });

//     try {
//       const response = await axios.post('http://localhost:3002/send-email', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       alert('Email sent successfully');
//       setFiles([]); // Clear files after sending
//       setSubject('');
//       setSelectedGroup('');
//       quillRef.current.root.innerHTML = ''; // Clear the editor
//     } catch (error) {
//       console.error('There was an error!', error);
//       alert('Failed to send email');
//     }
//   };

//   return (
//     <div className="email-composer">
//       <div className="header">
//         <img src={logo} alt="Logo" className="logo" />
//         <h1>Email Composer</h1>
//       </div>
//       <form onSubmit={handleSubmit} className="form-container">
//         <div className="form-group">
//           <label>Subject:</label>
//           <input
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             className="form-control"
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Group:</label>
//           <select
//             value={selectedGroup}
//             onChange={(e) => setSelectedGroup(e.target.value)}
//             className="form-control"
//             required
//           >
//             <option value="">Select a group</option>
//             {groups.map((group, index) => (
//               <option key={index} value={group}>{group}</option>
//             ))}
//           </select>
//         </div>
//         <div id="editor-container" className="editor-container"></div>
//         <input
//           id="file-input"
//           type="file"
//           accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//           multiple
//           onChange={handleFileUpload}
//           style={{ display: 'none' }}
//         />
//         <button
//           type="button"
//           className="btn btn-secondary attach-button"
//           onClick={() => document.querySelector('#file-input').click()}
//         >
//           Attach File
//         </button>
//         {files.length > 0 && (
//           <div className="uploaded-files">
//             <h4>Uploaded Files:</h4>
//             <ul>
//               {files.map((file, index) => (
//                 <li key={index}>{file.name}</li>
//               ))}
//             </ul>
//           </div>
//         )}
//         {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
//         <button type="submit" className="btn btn-primary send-button">Send Email</button>
//       </form>
//     </div>
//   );
// }

// export default EmailComposer;

//6-aug-24 1:13am

import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './EmailComposer.css';
import logo from './logo.png'; // Ensure you have a logo.png file in your project

function EmailComposer() {
  const [subject, setSubject] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [files, setFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState('');
  const quillRef = useRef(null);

  useEffect(() => {
    // Initialize Quill editor
    if (!quillRef.current) {
      quillRef.current = new Quill('#editor-container', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: '1' }, { header: '2' }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image']
          ]
        }
      });

      // Handle image pasting
      quillRef.current.getModule('toolbar').addHandler('image', () => {
        document.querySelector('#file-input').click();
      });
    }

    // Fetch groups from the server
    console.log('fetching groups');
    axios.get('https://newsletter.marriedtothemopnewsletter.online/groups')
    //axios.get('http://localhost:3002/groups')
      .then(response => {
        setGroups(response.data.groups);
      })
      .catch(error => {
        console.error('Error fetching groups:', error);
      });
  }, []);

  const handleFileUpload = (e) => {
    const filesArray = Array.from(e.target.files);
    // Validate file types and sizes here if needed
    setFiles(prevFiles => [...prevFiles, ...filesArray]);
    setUploadMessage('File(s) uploaded successfully');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const editorHtml = quillRef.current ? quillRef.current.root.innerHTML : '';

    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('body', editorHtml);
    formData.append('group', selectedGroup);

    // Append files to FormData
    files.forEach(file => {
      formData.append('attachments', file);
    });

    try {
      console.log('sending email');
      const response = await axios.post('https://newsletter.marriedtothemopnewsletter.online/compose', formData, {
      //const response = await axios.post('http://localhost:3002/compose', formData, {  
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Email sent successfully');
      setFiles([]); // Clear files after sending
      setSubject('');
      setSelectedGroup('');
      if (quillRef.current) {
        quillRef.current.root.innerHTML = ''; // Clear the editor
      }
    } catch (error) {
      console.error('There was an error!', error);
      alert('Failed to send email');
    }
  };

  return (
    <div className="email-composer">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Email Composer</h1>
      </div>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label>Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Group:</label>
          <select
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
            className="form-control"
            required
          >
            <option value="">Select a group</option>
            {groups.map((group, index) => (
              <option key={index} value={group}>{group}</option>
            ))}
          </select>
        </div>
        <div id="editor-container" className="editor-container"></div>
        <input
          id="file-input"
          type="file"
          accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          multiple
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
        <button
          type="button"
          className="btn btn-secondary attach-button"
          onClick={() => document.querySelector('#file-input').click()}
        >
          Attach File
        </button>
        {files.length > 0 && (
          <div className="uploaded-files">
            <h4>Uploaded Files:</h4>
            <ul>
              {files.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
        {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
        <button type="submit" className="btn btn-primary send-button">Send Email</button>
      </form>
    </div>
  );
}

export default EmailComposer;