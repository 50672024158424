// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import SubscriptionPage from './SubscriptionPage';
// import EmailComposer from './EmailComposer';
// import EmailWithAI from './EmailWithAI'; // Import the new EmailWithAI component
// import './App.css'; // Import your global CSS

// function App() {
//   return (
//     <Router>
//       <div className="app-container">
//         <nav className="navbar">
//           <Link to="/" className="nav-link">Subscribe</Link>
//           <Link to="/compose" className="nav-link">Compose Email</Link>
//           <Link to="/email-with-ai" className="nav-link">Email with AI</Link> {/* Add new link */}
//         </nav>
//         <Routes>
//           <Route path="/" element={<SubscriptionPage />} />
//           <Route path="/compose" element={<EmailComposer />} />
//           <Route path="/email-with-ai" element={<EmailWithAI />} /> {/* Add new route */}
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;

//6-aug-24 5:08pm

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import SubscriptionPage from './SubscriptionPage';
import EmailComposer from './EmailComposer';
import EmailWithAI from './EmailWithAI';
import Unsubscribe from './Unsubscribe'; // Import the Unsubscribe component
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <nav className="navbar">
          <Link to="/subscribe" className="nav-link">Subscribe</Link>
          <Link to="/compose" className="nav-link">Compose Email</Link>
          <Link to="/email-with-ai" className="nav-link">Email with AI</Link>
          <Link to="/unsubscribe" className="nav-link">Unsubscribe</Link>
        </nav>
        <Routes>
          <Route path="/subscribe" element={<SubscriptionPage />} />
          <Route path="/compose" element={<EmailComposer />} />
          <Route path="/email-with-ai" element={<EmailWithAI />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} /> {/* Add new route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
