// import React, { useState } from 'react';
// import axios from 'axios';
// import './EmailWithAI.css';
// import logo from './logo.png'; // Ensure you have a logo.png file in your project

// function EmailWithAI() {
//   const [inputText, setInputText] = useState('');
//   const [generatedSubject, setGeneratedSubject] = useState('');
//   const [generatedBody, setGeneratedBody] = useState('');

//   const handleGenerateContent = async () => {
//     try {
//       const response = await axios.post('http://localhost:3002/generate-content', { prompt: inputText });
//       const { subject, body } = response.data;
//       setGeneratedSubject(subject);
//       setGeneratedBody(body);
//     } catch (error) {
//       console.error('Error generating content:', error);
//     }
//   };

//   return (
//     <div className="email-with-ai">
//       <div className="header">
//         <img src={logo} alt="Logo" className="logo" />
//         <h1>Email with AI</h1>
//       </div>
//       <div className="form-container">
//         <div className="form-group">
//           <label>What do you want to write about?</label>
//           <textarea
//             value={inputText}
//             onChange={(e) => setInputText(e.target.value)}
//             className="form-control"
//             rows="4"
//             placeholder="Enter your topic here..."
//           ></textarea>
//         </div>
//         <button onClick={handleGenerateContent} className="btn btn-primary generate-button">Write for me!</button>
//         {generatedSubject && (
//           <>
//             <div className="result-group">
//               <h4>Generated Subject:</h4>
//               <p>{generatedSubject}</p>
//             </div>
//             <div className="result-group">
//               <h4>Generated Email Body:</h4>
//               <div className="generated-body" dangerouslySetInnerHTML={{ __html: generatedBody }}></div>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

// export default EmailWithAI;

//3-aug-27 11:16pm

import React, { useState } from 'react';
import axios from 'axios';
import './EmailWithAI.css';

const EmailWithAI = () => {
  const [prompt, setPrompt] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [error, setError] = useState('');

  const handleGenerateContent = async () => {
    try {
      console.log('starting AI part');
      const response = await axios.post('https://newsletter.marriedtothemopnewsletter.online/generate-content', { prompt });
      //const response = await axios.post('http://localhost:3002/generate-content', { prompt });
      setSubject(response.data.subject);
      setBody(response.data.body);
      setError('');
    } catch (error) {
      console.error('Error generating content:', error);
      setError('Failed to generate content. Please try again.');
    }
  };

  return (
    <div className="email-ai-container">
      <h2>Email Content Generator</h2>
      <textarea
        placeholder="What do you want to write about?"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <button onClick={handleGenerateContent}>Write for me!</button>
      {error && <p className="error">{error}</p>}
      {subject && (
        <div>
          <h3>Generated Subject:</h3>
          <p>{subject}</p>
        </div>
      )}
      {body && (
        <div>
          <h3>Generated Body:</h3>
          <p>{body}</p>
        </div>
      )}
    </div>
  );
};

export default EmailWithAI;

